import React from "react"
import Modal from "components/modal/Modal"
import { DataRow } from "components/DataRow/DataRow"
import Button from "components/button"
import { useMutation } from "react-query"
import {
  approveWorkflowRequest,
  declineWorkflowRequest,
} from "services/api/workflow"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import env from "config/env"
import Status from "components/Status/Status"

interface SettlementApprovalModalProps {
  isOpen: boolean
  closeModal: () => void
  originalData: SettlementApprovalRequestType
}

const SettlementApprovalModal: React.FC<SettlementApprovalModalProps> = ({
  isOpen,
  closeModal,
  originalData,
}) => {
  const dataToDisplay = {
    merchantId: originalData.merchantId,
    merchantName: originalData.merchantName,
    totalTransactionAmount: originalData.totalTransactionAmount,
    totalPayoutAmount: originalData.totalPayoutAmount,
    totalIncome: originalData.totalIncome,
    status: (
      <Status variant={originalData.status}>{originalData.status}</Status>
    ),
    settlementDate: originalData.settlementDate,
    createdDate: originalData.createdDate,
    updatedDate: originalData.updatedDate,
    numberOfTransactions: originalData.numberOfTransactions,
  }

  const { mutate: handleRequestApproval, isLoading: isApproving } = useMutation(
    approveWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          queryKeys.settlement.fetchSettlementApprovals
        )
        toast.success(response.data.message)
        closeModal()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const { mutate: handleRequestDecline, isLoading: isDeclining } = useMutation(
    declineWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          queryKeys.settlement.fetchSettlementApprovals
        )
        toast.success(response.data.message)
        closeModal()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      contentContainerClass="!max-w-3xl"
    >
      <h3 className="p-6 text-center text-3xl font-bold">
        Approval Request Details
      </h3>
      <div className="flex flex-col gap-8 p-6">
        {Object.entries(dataToDisplay).map(([label, value]) => (
          <DataRow key={label} label={label} value={value} />
        ))}
      </div>
      <div className="flex justify-end gap-8 p-6">
        <Button
          variant="danger"
          onClick={() => {
            handleRequestDecline({
              approvalRequestCode: originalData.approvalRequestCode,
              workFlowCode: originalData.workFlowCode,
              clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
              reason: "Declined by user",
            })
          }}
          loading={isDeclining}
        >
          Decline
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleRequestApproval({
              approvalRequestCode: originalData.approvalRequestCode,
              workFlowCode: originalData.workFlowCode,
              clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
            })
          }}
          loading={isApproving}
        >
          Approve
        </Button>
      </div>
    </Modal>
  )
}

export default SettlementApprovalModal
