import React from "react"
import useSettlement from "./hooks/useSettlement"
import Table from "components/Table"
import { queryKeys } from "constants/queryKeys"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { approvalRequestColumns } from "./Table/approvalRequestColumns"

const SettlementMarkoffList: React.FC = () => {
  const { settlement } = useSettlement(
    queryKeys.settlement.fetchSettlementApprovals
  )

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_PAYOUTS]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={approvalRequestColumns}
            data={settlement.settlementMarkoffList}
            isLoading={
              settlement.isLoadingMarkoff || settlement.refetchingMarkoff
            }
            loadingSkeletonCount={10}
          />
        </div>
      </div>
    </PermissionGate>
  )
}

export default SettlementMarkoffList
