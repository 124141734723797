import React, { FC, useEffect, useRef, useState } from "react"
import { useOTP } from "./hooks/useOTP"

let currentOTPIndex = 0

interface Props {
  onChange: (otp: string[]) => void
  fieldCount: number
}

const OTPField: FC<Props> = ({ fieldCount, onChange }): JSX.Element => {
  const [otp, setOtp] = useOTP(fieldCount)

  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target
    const newOTP: string[] = [...otp]
    newOTP[currentOTPIndex] = value.substring(value?.length - 1)

    if (!value) setActiveOTPIndex(currentOTPIndex - 1)
    else setActiveOTPIndex(currentOTPIndex + 1)

    setOtp(newOTP)
    if (onChange) {
      onChange(newOTP)
    }
  }

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index
    if (key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [activeOTPIndex])

  return (
    <div className="flex items-center justify-center space-x-2">
      {otp.map((_, index) => {
        return (
          <React.Fragment key={index}>
            <input
              ref={index === activeOTPIndex ? inputRef : null}
              type="number"
              onChange={handleOnChange}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              className="bg-transparent spin-button-none h-11-5 w-[2.8rem] rounded border-2 
              border-gray-20 text-center text-xl font-semibold
               text-gray-10 outline-none transition focus:border-gray-20 focus:text-gray-10 focus:ring-gray-20"
              value={otp[index]}
            />
            {index === otp?.length - 1 ? null : (
              <span className="bg-gray-400 w-2 py-0.5" />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default OTPField
