import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { MerchantApprovalRequest } from "types/merchants"
import MerchantApprovalModal from "../Details/ApprovalRequestDetails"

const columnHelper = createColumnHelper<MerchantApprovalRequest>()

export const approvalRequestColumns = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.businessName, {
    id: "businessName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor("state", {
    header: () => <span>State</span>,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const [showModal, setShowModal] = useState(false)

      function closeModal() {
        setShowModal(false)
      }

      return (
        <>
          <span
            onClick={() => {
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <MerchantApprovalModal
            isOpen={showModal}
            closeModal={closeModal}
            originalData={originalData}
          />
        </>
      )
    },
  }),
]
