import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import SettlementApprovalModal from "../Details/ApprovalRequestDetails"
import { numberUtils } from "utils/numberUtils"

const columnHelper = createColumnHelper<SettlementApprovalRequestType>()

export const approvalRequestColumns = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.merchantName, {
    id: "merchantName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Merchant Name</span>,
  }),
  columnHelper.accessor("totalTransactionAmount", {
    header: () => <span>Total Transaction Amount</span>,
    cell: (info) => (
      <span>{numberUtils.formatCustomAmount(info.getValue(), "NGN")}</span>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const [showModal, setShowModal] = useState(false)

      function closeModal() {
        setShowModal(false)
      }

      return (
        <>
          <button
            onClick={() => {
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </button>

          <SettlementApprovalModal
            isOpen={showModal}
            closeModal={closeModal}
            originalData={originalData}
          />
        </>
      )
    },
  }),
]
